<template>
  <form>

    <header>
      <img src="@/assets/img/demo/demo_contact.png" class="w100">
    </header>


    <section class="contents">

      <div class="contentInner">
        <h2>お問い合わせ送信完了</h2>
        <div>この度はお問い合わせありがとうございます。<br>
          <br>
          お問い合わせいただきました内容についてはカスタマーセンターで内容を確認の後、<br>
          ご登録いただいておりますメールアドレスにご連絡いたしますので、今しばらくお待ちください。<br>
          <br>
          なお、内容によって返信にお時間かかる場合がございます。<br>
          あらかじめご了承ください。</div>

        <div class="bt_form mtb_20"><router-link :to="{name: 'home'}">トップへ</router-link></div>

      </div><!--.contentInner-->

    </section><!--.contents-->

  </form>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'ContactSend',
  components: {
  }
}
</script>
